import axios from 'axios'

export default {
  namespaced: true,
  state: {
    listOfEntities: [],
  },
  getters: {},
  mutations: {
    GET_LIST_OF_ENTITIES(state, entities) {
      state.listOfEntities = entities
    },
  },
  actions: {
    getEntities({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('https://activities.muslimdo.com/api/internalops/live/getEntitesDDL', data)
          .then(response => {
            commit('GET_LIST_OF_ENTITIES', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addLive(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('https://activities.muslimdo.com/api/internalops/live', data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
