/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormInvalidFeedback,
  BBadge, BDropdown, BDropdownItem, BPagination, VBToggle, VBModal, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import axios from 'axios'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, email, url } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import live from '../live'
import LiveModule from '../LiveModule'

Vue.use(VueSweetalert2)
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup() {
    const LIVE_MODULE = 'liveModule'
    // Register module
    if (!store.hasModule(LIVE_MODULE)) store.registerModule(LIVE_MODULE, LiveModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LIVE_MODULE)) store.unregisterModule(LIVE_MODULE)
    })
    const {
      liveColumn, addLive, entityData, addLiveData, addLiveModal, refetchData,
    } = live()
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
      liveColumn,
      addLive,
      entityData,
      addLiveData,
      addLiveModal,
      refetchData,
    }
  },
  methods: {
    hideModal() {
      this.addLive = {}
      this.$refs.addLiveModal.hide()
    },
  },
}
