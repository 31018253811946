/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function live() {
  const toast = useToast()

  const addLiveModal = ref(null)

  const liveColumn = [
    { key: 'title', label: 'Start Date / Time' },
    { key: 'start', label: 'Start Date / Time' },
    { key: 'end', label: 'End Date / Time' },
    { key: 'likes', label: 'NO. of Likes' },
    { key: 'views', label: 'NO. of Views' },
    { key: 'total', label: 'Total Of Donations' },
    { key: 'actions' },
  ]

  const addLive = ref({
    activity_name: null,
    session_link: null,
    session_start: null,
    session_end: null,
    entity_id: null,
    activity_description: null,
    activity_type: 'Live',
  })

  const entity = ref('')

  const getEN = () => {
    store.dispatch('liveModule/getEntities', entity.value).then(res => {
      console.log(res)
    }).catch()
  }
  getEN()
  const entityData = computed(() => store.state.liveModule.listOfEntities)

  const refetchData = () => {
    addLiveModal.value.hide()
  }

  const addLiveData = () => {
    store.dispatch('liveModule/addLive', addLive.value).then(res => {
      console.log(res)
      if (res.data.status === 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        addLive.value = {}
        refetchData()
      // eslint-disable-next-line no-empty
      } else {}
    }).catch(err => {
      if (err.response.data.errors) {
        console.log(err.response.data.errors)
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors.session_link.toString(),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
      if (err.response.data.errors) {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors.session_link.toString(),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    })
  }

  return {
    liveColumn,
    addLive,
    entityData,
    addLiveData,
    addLiveModal,
    refetchData,
  }
}
